const state = {
  voucherList: []
};

// Getter functions
const getters = {
  getVoucherList: state => {
    return state.voucherList;
  },
  getVoucherById: state => voucherCode => {
    return state.voucherList.find(v => {
      return v.voucherCode == voucherCode;
    });
  }
};

// Actions
const actions = {
  // fetchVariable1({ commit }) {
  //     return new Promise( (resolve, reject) => {
  //            // Make network request and fetch data
  //            // and commit the data
  //            commit('SET_VARIABLE_1', data);
  //            resolve();
  //     }
  // }
};
// Mutations
const mutations = {
  addVoucher(state, voucher) {
    let index = state.voucherList.findIndex(value => {
      return value.voucherCode == voucher.voucherCode;
    });
    console.log("Index" + index);
    if (index < 0) {
      state.voucherList.push(voucher);
    }
  },
  removeVoucher(state, index) {
    state.voucherList.splice(index, 1);
  },
  removeVoucherByVoucherCode(state, voucherCode) {
    let index = state.voucherList.findIndex(value => {
      return value.voucherCode == voucherCode;
    });
    state.voucherList.splice(index, 1);
  },
  changeVoucher(state, voucher) {
    console.log("changeVoucher");
    let index = state.voucherList.findIndex(value => {
      return value.voucherCode == voucher.voucherCode;
    });
    if (index >= 0) state.voucherList[index] = voucher;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
