import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './service/registerServiceWorker.js'
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import axios from "./config";
import VueAxios from "vue-axios";
import moment from "moment";
import lodash from "lodash";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import numeral from "numeral";

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);

Vue.prototype.$moment = moment;
Vue.prototype.$swal = swal;
Vue.prototype.$swal2 = swal2;
Vue.prototype.$_ = lodash;
Vue.prototype.$numeral = lodash;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

Vue.filter("perssengerCount", function (person,round) {
  if (person == 0 | round > 0) {
    return ""
  } else if (person < 9) {
    return "0" + person;
  } else {
    return person;
  }
});

Vue.filter("defaultPaymentFormat", function (value) {
  if (value != Number.parseInt(value)) {
    return numeral(value).format('-0,0.00');
  }
  return numeral(value).format('-0,0');
});

Vue.filter("defaultPaymentReturnBlankFormat", function (value) {
  if (value == 0 || value == undefined) {
    return "";
  } else if (value != Number.parseInt(value)) {
    return numeral(value).format('-0,0.00');
  }
  return numeral(value).format('-0,0');
});

Vue.filter("defaultPaymentWithDollarSignFormat", function (value) {
  if (value != Number.parseInt(value)) {
    return numeral(value).format('$ -0,0.00');
  }
  return numeral(value).format('$ -0,0');
});

Vue.filter("defaultPaymentWithDollarSignFormatReturnBlank", function (value) {
  if (value == 0 || value == undefined) {
    return "";
  } else if (value != Number.parseInt(value)) {
    return numeral(value).format('$ -0,0.00');
  }
  return numeral(value).format('$ -0,0');
});
Vue.filter("formatDate", function (value) {
  if (value) {
    return moment(value, "DD-MM-YYYY HH:mm:ss", true).format(
      "DD/MM/YYYY hh:mmA"
    );
  }
});
Vue.filter("reportDate", function (value) {
  if (value) {
    return moment(value, "DD-MM-YYYY", true).format(
      "DD MMM YY"
    );
  }
});
Vue.filter("voucherTravelDate", function (value) {
  if (value) {
    return moment(value, "DD-MM-YYYY HH:mm:ss", true).format(
      "DD MMM YY"
    );
  }
});
Vue.filter("voucherPrintDate", function (value) {
  if (value) {
    return moment(value, "DD MMM YY", true).format(
      "DD MMM YYYY"
    );
  }
});

Vue.filter("voucherExpenseDate", function (value) {
  if (value) {
    return moment(value, "DD-MM-YYYY HH:mm:ss", true).format(
      "DD MMM YYYY"
    );
  }
});

Vue.filter("airlineDetailReport", function (value) {
  if (value) {
    return moment(value, "YYYY-MM-DD", true).format(
      "DD MMM YYYY"
    );
  }
});

// update at 9/4/2020 for balance return blank or 0 or value
Vue.filter("defaultBalanceReturnBlankFormat", function (value,receive) {
  if(receive != 0 ){
    if (value == 0 || value == undefined) {
      return 0;
    } else if (value != Number.parseInt(value)) {
      return numeral(value).format('-0,0.00');
    }
    return numeral(value).format('-0,0');
  }else{
    return ""
  }
});
Vue.filter("numberFormat", function (value) {
  return numeral(value).format('0,0');
});////return parseFloat(value).toFixed(4);
Vue.filter("doubleFormat", function (value) {
  return parseFloat(value).toFixed(2);
});////
// update at 9/4/2020 for balance return blank or 0 or value
Vue.filter("defaultBalanceWithDollarSignFormatReturnBlank", function (value,receive) {
  if(receive != 0 ){
  if (value == 0 || value == undefined) {
    return 0;
  } else if (value != Number.parseInt(value)) {
    return numeral(value).format('$ -0,0.00');
  }
  return numeral(value).format('$ -0,0');
  }else{
    return ""
  }
});

Vue.filter("forPersonFormat",function(value){
  var personList = value.split(',');
  var closeName = personList.length-1
  if(closeName != 0){
    return personList[0]+" +"+closeName;
  }else{
    return personList[0];
  }
})