import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: "#4CAF50",
        secondary: "#388E3C",
        accent: "#8BC34A",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
});
