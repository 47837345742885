import Vue from "vue";
import Router from "vue-router";
import PageNotFound from "../views/PageNotFound.vue";
// import store from "./store.js";
Vue.use(Router);

const routes = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      redirect: "/tempVoucher"
    },
    {
      path: "/voucherList",
      name: "voucherList",
      component: () =>
        import(/* webpackChunkName: "voucherList" */ "../views/VoucherList.vue")
    },
    {
      path: "/voucherlistall",
      name: "voucherlistall",
      component: () =>
        import(/* webpackChunkName: "voucherlistall" */ "../views/VoucherListAll.vue")
    },
    {
      path: "/test",
      name: "test",
      component: () =>
        import(/* webpackChunkName: "test" */ "../views/test.vue")
    },
    {
      path: "/voucherPrint",
      name: "voucherPrint",
      component: () =>
        import(
          /* webpackChunkName: "voucherPrint" */ "../views/VoucherPrint.vue"
        )
    },
    {
      path: "/voucherPrintChange",
      name: "voucherPrintChange",
      component: () =>
        import(
          /* webpackChunkName: "voucherPrintChange" */ "../views/VoucherPrintChange.vue"
        )
    },
    {
      path: "/voucherExpensePrint",
      name: "voucherExpensePrint",
      component: () =>
        import(
          /* webpackChunkName: "voucherExpensePrint" */ "../views/VoucherExpensePrint.vue"
        )
    },
    {
      path: "/voucherCashbackPrint",
      name: "voucherCashbackPrint",
      component: () =>
        import(
          /* webpackChunkName: "voucherCashbackPrint" */ "../views/VoucherCashbackPrint.vue"
        )
    },
    {
      path: "/about",
      name: "about",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/About.vue")
    },
    {
      path: "/setting",
      name: "setting",
      component: () =>
        import(/* webpackChunkName: "setting" */ "../views/Setting.vue")
    },
    {
      path: "/companyProfile",
      name: "companyProfile",
      component: () =>
        import(
          /* webpackChunkName: "CompanyProfile" */ "../views/CompanyProfile.vue"
        )
    },
    {
      path: "/contacts",
      name: "contact",
      component: () =>
        import(/* webpackChunkName: "Contact" */ "../views/Contact.vue")
    },
    {
      path: "/airline",
      name: "airline",
      component: () =>
        import(/* webpackChunkName: "airline" */ "../views/AirlineView.vue")
    },
    {
      path: "/account",
      name: "account",
      component: () =>
        import(/* webpackChunkName: "Account" */ "../views/UserAccount.vue")
    },
    {
      path: "/voucher/:voucherId",
      name: "voucher",
      component: () =>
        import(/* webpackChunkName: "Voucher" */ "../views/VoucherDetail.vue")
    },
    {
      path: "/tempVoucher",
      name: "tempVoucher",
      component: () =>
        import(/* webpackChunkName: "TempVoucher" */ "../views/TempVoucher.vue")
    },
    {
      path: "/nameChange/voucher/:voucherId",
      name: "nameChange",
      component: () =>
        import(/* webpackChunkName: "NameChange" */ "../views/NameChange.vue")
    },
    {
      path: "/nameChange/voucher/:voucherId/changes/:changesId",
      name: "nameChangeUpdate",
      component: () =>
        import(/* webpackChunkName: "NameChange" */ "../views/NameChange.vue")
    },
    {
      path: "/dateSectorChange/voucher/:voucherId",
      name: "dateSectorChange",
      component: () =>
        import(
          /* webpackChunkName: "NameChange" */ "../views/DateSectorChange.vue"
        )
    },
    {
      path: "/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "login" */ "../views/Login.vue")
    },
    {
      path: "/refund/voucher/:voucherId",
      name: "refund",
      component: () =>
        import(/* webpackChunkName: "refund" */ "../views/Refund.vue")
    },
    {
      path: "/historyComponent",
      name: "historyComponent",
      component: () =>
        import(
          /* webpackChunkName: "historyComponent" */ "../components/HistoryComponent.vue"
        )
    },
    {
      path: "/customerReport",
      name: "customerReport",
      component: () =>
        import(
          /* webpackChunkName: "customerReport" */ "../views/ReportCustomer.vue"
        )
    },
    {
      path: "/airlineReport",
      name: "airlineReport",
      component: () =>
        import(
          /* webpackChunkName: "airlineReport" */ "../views/ReportAirline.vue"
        )
    },
    {
      path: "/sectorReport",
      name: "sectorReport",
      component: () =>
        import(
          /* webpackChunkName: "sectorReport" */ "../views/ReportSector.vue"
        )
    },
    {
      path: "/customerDetailReport",
      name: "customerDetailReport",
      component: () =>
        import(
          /* webpackChunkName: "customerDetailReport" */ "../views/ReportCustomerDetail.vue"
        )
    },
    {
      path: "/airlineDetailReport",
      name: "airlineDetailReport",
      component: () =>
        import(
          /* webpackChunkName: "airlineDetailReport" */ "../views/ReportAirlineDetail.vue"
        )
    },
    {
      path: "/airlineWithSectorReport",
      name: "airlineWithSectorReport",
      component: () =>
        import(
          /* webpackChunkName: "airlineWithSectorReport" */ "../views/ReportAirlineWithSector.vue"
        )
    },
    {
      path: "/receiveVoucher",
      name: "receiveVoucher",
      component: () =>
        import(
          /* webpackChunkName: "receiveVoucher" */ "../views/VoucherReceive.vue"
        )
    },
    {
      path: "/bill",
      name: "bill",
      component: () =>
        import(
          /* webpackChunkName: "Bill" */ "../views/Bill.vue"
        )
    },
    {
      path: "/cashtype",
      name: "cashtype",
      component: () =>
        import(
          /* webpackChunkName: "Cash" */ "../views/Cash.vue"
        )
    },
    {
      path: "/income",
      name: "income",
      component: () =>
        import(
          /* webpackChunkName: "Income" */ "../views/Income.vue"
        )
    },
    {
      path: "/expense",
      name: "expense",
      component: () =>
        import(
          /* webpackChunkName: "Expense" */ "../views/Expense.vue"
        )
    },
    {
      path: "/payment",
      name: "payment",
      component: () =>
        import(
          /* webpackChunkName: "Payment" */ "../views/Payment.vue"
        )
    },
    {
      path: "/commission",
      name: "commission",
      component: () =>
        import(
          /* webpackChunkName: "Payment" */ "../views/Brocker.vue"
        )
    },
    {
      path: "/incomereport",
      name: "incomereport",
      component: () =>
        import(
          /* webpackChunkName: "Payment" */ "../views/ReportIncome.vue"
        )
    },
    {
      path: "/billreport",
      name: "billreport",
      component: () =>
        import(
          /* webpackChunkName: "Payment" */ "../views/ReportBill.vue"
        )
    },
    {
      path: "/expensereport",
      name: "expensereport",
      component: () =>
        import(
          /* webpackChunkName: "Payment" */ "../views/ReportExpense.vue"
        )
    },
    {
      path: "/debit",
      name: "debit",
      component: () =>
        import(
          /* webpackChunkName: "Payment" */ "../views/Debit.vue"
        )
    },
    {
      path: "/balance",
      name: "balance",
      component: () =>
        import(
          /* webpackChunkName: "balance" */ "../views/Balance.vue"
        )
    },
    {
      path: "/invoice",
      name: "invoice",
      component: () =>
        import(
          /* webpackChunkName: "invoice" */ "../views/Invoice.vue"
        )
    },
    {
      path: "/invList",
      name: "invList",
      component: () =>
        import(
          /* webpackChunkName: "invList" */ "../views/InvoiceList.vue"
        )
    },
    {
      path: "/expList",
      name: "expList",
      component: () =>
        import(
          /* webpackChunkName: "expList" */ "../views/ExpensesList.vue"
        )
    },
    {
      path: "/invPrint",
      name: "invPrint",
      component: () =>
        import(
          /* webpackChunkName: "invPrint" */ "../views/VoucherInvoice.vue"
        )
    },  
    {
      path: "/invpayment",
      name: "invpayment",
      component: () =>
        import(
          /* webpackChunkName: "invpayment" */ "../views/InvoicePayment.vue"
        )
    },  
    {
      path: "/exppayment",
      name: "exppayment",
      component: () =>
        import(
          /* webpackChunkName: "exppayment" */ "../views/ExpensesPayment.vue"
        )
    },  
    {
      path: "/invpayprint",
      name: "invpayprint",
      component: () =>
        import(
          /* webpackChunkName: "invPayment" */ "../views/VoucherPayment.vue"
        )
    }, 
    {
      path: "/rpuser",
      name: "rpuser",
      component: () =>
        import(
          /* webpackChunkName: "rpuser" */ "../views/ReportUser.vue"
        )
    },  
    {
      path: "/ui",
      name: "ui",
      component: () =>
        import(
          /* webpackChunkName: "ui" */ "../views/UiAccept.vue"
        )
    },  
    { path: "*", component: PageNotFound }
  ]
});

// const router = new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

export default routes;
