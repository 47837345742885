import Vue from "vue";
import Vuex from "vuex";
import TempVoucher from "../store/TempVoucher";
import VuexPersistance from "vuex-persist";
import axios from "../config";

Vue.use(Vuex);

const vuexLocal = new VuexPersistance({
  storage: window.localStorage
});

export default new Vuex.Store({
  state: {
    userAccount: {
      userName: "",
      profileName: "",
      password: "",
      role: ""
    },
    uiAccept:{},
  },
  mutations: {
    updateUserAccount: function(state, value) {
      state.userAccount = value;
      if (value.password != undefined) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `DELI    ${value.password}`;
      } else {
        axios.defaults.headers.common["Authorization"] = `DELI ''''''`;
      }
    },
    removeUserAccount: function(state) {
      state.userAccount = {};
    },
    updateUiAccept: function(state,value) {
      state.uiAccept = value;
    },
  },
  actions: {},
  modules: {
    TempVoucher
  },
  plugins: [vuexLocal.plugin]
});
