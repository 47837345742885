import BasicService from "./BasicService";

class Account extends BasicService {
  constructor() {
    super();
  }

  // Change User Pass
  changePassword(currentPassword, newPassword) {
    let url = `userAccounts/changepassword/`;
    return this.axios
      .get(url, {
        params: {
          "currentPassword":currentPassword,
          "newPassword":newPassword
        }
      })
      .then(request => request.data);
  }

  //   userAccounts
  deleteAccount(userAccounts) {
    let url = `/userAccounts/${userAccounts.userAccountId}`;
    return this.axios.delete(url, userAccounts).then(request => request.data);
  }
  updateAccount(userAccounts) {
    let url = `/userAccounts/${userAccounts.userAccountId}`;
    return this.axios.put(url, userAccounts).then(request => request.data);
  }
  addAccount(userAccounts) {
    let url = `/userAccounts`;
    return this.axios.post(url, userAccounts).then(request => request.data);
  }
  getAccount() {
    let url = `/userAccounts`;
    return this.axios.get(url).then(request => request.data);
  }
  getUi(userType) {
    let url = `/ui`;
    return this.axios.get(url,{params:{
      userType
    }}).then(request => request.data);
  }
  updateUi(uiAccept) {
    let url = `/ui/update`;
    return this.axios.put(url,uiAccept).then(request => request.data);
  }
  getUiByUserId() {
    let url = `/ui/userId`;
    return this.axios.get(url).then(request => request.data);
  }
}

const accountService = new Account();
export default accountService;
